import {
  LocationStoreRequest,
  PostInternalLocationsApiResponse,
  qubyApiPhp,
} from '@/api/generated/qubyApiPhp'

const envVariables = require('@/constants/env')

export const qubyApiPhpEgo = qubyApiPhp.injectEndpoints({
  endpoints: (build) => ({
    putInternalLocations: build.mutation<
      PostInternalLocationsApiResponse,
      PutInternalLocationsApiArg
    >({
      query: (queryArg) => {
        return {
          url: `${envVariables.PHP_API_URL}v1.1/locations/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.locationStoreRequest,
        }
      },
      invalidatesTags: ['Locations'],
    }),
  }),
  overrideExisting: true,
})

export type PutInternalLocationsApiArg = {
  id: string
  locationStoreRequest: LocationStoreRequest
}
export type RunRequestStatusForChangeStatusEnum =
  | 'NEW'
  | 'IN_PROGRESS'
  | 'DONE'
  | 'CANCELLED'
  | 'ERROR'
