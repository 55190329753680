import React, { FC, Fragment, useEffect, useState } from 'react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  Row,
  RowSelectionState,
  useReactTable,
} from '@tanstack/react-table'
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Skeleton,
  Table,
  TableCellProps,
  TableColumnHeaderProps,
  TableContainer,
  TableRowProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { EnumOrder, TableMenuItems } from '@/types/table'
import { colors } from '@/theme/colors'
import SelectCell from './help/SelectCell/SelectCell'
import { observer } from 'mobx-react-lite'
import SortIcon from '@/components/atoms/table_assets/SortIcon/SortIcon'
import MenuCell from './help/MenuCell'
import Pagination from '@/components/moleculs/Table/help/Pagination/Pagination'
import { QueryPageTypes } from '@/types/api.types'
import { PaginationPageProps } from '@/components/moleculs/Table/help/Pagination/Pagination.types'
import { motion } from 'framer-motion'
import { BaseOrderTypeEnum } from '@/api/generated/qubyApiPhp'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'

interface Props<T, OrderType extends string> {
  onMouseInRowMenuButton?: (row: Row<T>) => void
  data?: T[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: any[]
  loading?: boolean
  isLoading?: boolean
  isFetching?: boolean
  page?: QueryPageTypes
  selectedRows?: string[]
  onRowSelectionChange?: (selected: string[]) => void
  getMenuItems?: (row: T) => TableMenuItems[]
  getRowCanExpand?: (row: Row<T>) => boolean
  renderSubComponent?: (props: {
    row: Row<T>
    refetch?: () => void
  }) => React.ReactElement | null
  th?: TableColumnHeaderProps
  td?: TableCellProps
  pageSettings?: PaginationPageProps
  skeletonRowHeight?: string
  orderSettings?: {
    order?: BaseOrderTypeEnum
    orderBy?: OrderType
    setOrder: (order: {
      order?: BaseOrderTypeEnum
      orderBy?: OrderType
    }) => void
  }
  refetch?: () => void
  setData?: React.Dispatch<React.SetStateAction<T[]>>
  draggable?: boolean
  draggableChangeStateList?: React.Dispatch<React.SetStateAction<boolean>>
}

const tableLoaderSkeleton = (
  row: number,
  columns: number,
  skeletonRowHeight: string,
) =>
  new Array(row).fill('').map((_, i) => (
    <Tr key={i}>
      {new Array(columns).fill('').map((_, i) => (
        <Td px="3" py="2" key={i}>
          <Skeleton height={skeletonRowHeight} isLoaded={false} />
        </Td>
      ))}
    </Tr>
  ))

const columnHelper = createColumnHelper<{ id: string }>()
const selectCell = columnHelper.accessor('id', {
  id: 'select',
  header: ({ table }) => (
    <SelectCell
      {...{
        checked: table.getIsAllRowsSelected(),
        indeterminate: table.getIsSomeRowsSelected(),
        onChange: table.getToggleAllRowsSelectedHandler(),
      }}
    />
  ),
  cell: ({ row }) => (
    <SelectCell
      {...{
        checked: row.getIsSelected(),
        indeterminate: row.getIsSomeSelected(),
        onChange: row.getToggleSelectedHandler(),
      }}
    />
  ),
})

const _Table = <T extends { id: string }, OrderType extends string = string>(
  props: Props<T, OrderType>,
) => {
  const {
    columns,
    data,
    loading = false,
    isLoading = loading,
    isFetching,
    selectedRows,
    onRowSelectionChange,
    getMenuItems,
    renderSubComponent,
    getRowCanExpand = () => false,
    pageSettings,
    onMouseInRowMenuButton,
    orderSettings,
    td,
    skeletonRowHeight = '16px',
    refetch = () => {},
    setData,
    draggable = false,
    draggableChangeStateList,
  } = props
  const hasMenuItems = Boolean(getMenuItems)

  const [rowSelection, setSelectedRows] = useState<RowSelectionState>({})
  const [currentRow, setCurrentRow] = useState<Row<T> | null>(null)

  const { getRowModel, getHeaderGroups, setExpanded, setRowSelection } =
    useReactTable<T>({
      columns: [
        ...(onRowSelectionChange ? [selectCell] : []),
        ...columns.map(({ enableSorting, ...other }) => ({
          enableSorting: enableSorting || false,
          ...other,
        })),
      ],
      data: data || [],
      getRowId: ({ id }) => id,
      state: {
        rowSelection,
      },
      getRowCanExpand,
      onRowSelectionChange: (o) => {
        if (typeof o === 'function' && onRowSelectionChange) {
          onRowSelectionChange(
            Object.entries(o(rowSelection)).map(([key]) => key),
          )
        }
        setSelectedRows(o)
      },
      getCoreRowModel: getCoreRowModel(),
    })

  useEffect(() => {
    if (selectedRows && !isLoading) {
      setRowSelection(Object.fromEntries(selectedRows.map((id) => [id, true])))
    }
  }, [selectedRows, isLoading, setRowSelection])

  useEffect(() => {
    setExpanded(() => true)
  }, [setExpanded])
  const plus = (renderSubComponent ? 1 : 0) + (hasMenuItems ? 1 : 0)
  const dragStartHandler = (
    e: React.DragEvent<HTMLTableRowElement>,
    row: Row<T>,
  ) => {
    setCurrentRow(row)
  }

  const dragOverHandler = (e: React.DragEvent<HTMLTableRowElement>) => {
    e.preventDefault()
  }
  const dropHandler = (
    e: React.DragEvent<HTMLTableRowElement>,
    row: Row<T>,
  ) => {
    e.preventDefault()
    if (!setData || !row?.original || !currentRow?.original) {
      return
    }
    setData((prevData) => {
      if (!prevData) return []
      return prevData.map((el) => {
        if (el?.id === row.original.id) {
          return currentRow.original
        }
        if (el?.id === currentRow.original.id) {
          return row.original
        }
        return el
      })
    })
    draggableChangeStateList && draggableChangeStateList(true)
  }

  return (
    <Grid height="100%" gridTemplateRows={'1fr max-content'}>
      <TableContainer
        borderY={`1px solid ${colors.neutral[400]}`}
        height="100%"
        overflowY="scroll"
        width="100%"
      >
        <Table
          width="100%"
          position="relative"
          borderRadius="4px"
          borderX={`1px solid ${colors.neutral[400]}`}
        >
          <Thead
            zIndex="3"
            boxShadow={`0 1px 0 ${colors.neutral[400]}`}
            position="sticky"
            top="0"
            transform={'translateY(-1px)'}
            bg={colors.neutral['300']}
          >
            {getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {renderSubComponent ?
                  <Th p={0}></Th>
                : null}
                {hasMenuItems ?
                  <Th p={0}></Th>
                : null}
                {headerGroup.headers.map((header) => {
                  return (
                    <Th px="3" key={header.id}>
                      <Grid gridTemplateColumns="1fr max-content">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {(
                          header.column.id !== 'select' &&
                          header.column.getCanSort()
                        ) ?
                          <Box
                            as={motion.button}
                            ml={2}
                            opacity={
                              (
                                orderSettings?.orderBy ===
                                header.column.id.toUpperCase()
                              ) ?
                                1
                              : 0.42
                            }
                            transition={'.12s ease-in'}
                            _hover={{
                              transform: 'scale(1.12)',
                              opacity: 1,
                            }}
                            _active={{
                              transform: 'scale(0.92)',
                            }}
                          >
                            <SortIcon
                              onClick={(nextSort) => {
                                if (orderSettings) {
                                  if (
                                    orderSettings.orderBy ===
                                      header.column.id.toUpperCase() &&
                                    orderSettings.order === 'DESC'
                                  ) {
                                    orderSettings.setOrder({})
                                  } else {
                                    orderSettings.setOrder({
                                      orderBy:
                                        header.column.id.toUpperCase() as OrderType,
                                      order: nextSort || 'ASC',
                                    })
                                  }
                                }
                              }}
                              sort={
                                (
                                  orderSettings?.orderBy ===
                                  header.column.id.toUpperCase()
                                ) ?
                                  (orderSettings?.order as EnumOrder)
                                : undefined
                              }
                            />
                          </Box>
                        : null}
                      </Grid>
                    </Th>
                  )
                })}
              </Tr>
            ))}
          </Thead>

          <Tbody
            transition={'.32s ease-in-out'}
            opacity={isFetching ? 0.42 : 1}
          >
            {!isLoading ?
              data?.length === 0 && pageSettings && pageSettings.count === 0 ?
                'Ничего не найдено'
              : getRowModel().rows.map((row, i) => {
                  const menuItems =
                    getMenuItems ?
                      getMenuItems(row.original)
                        .filter(({ isHidden }) => isHidden !== true)
                        .map(({ onClick, label, icon = null }, i) => (
                          <MenuItem
                            key={i}
                            display="grid"
                            gridAutoFlow="column"
                            gridGap="10px"
                            onClick={onClick}
                          >
                            {icon}
                            <Text textStyle="text4">{label}</Text>
                          </MenuItem>
                        ))
                    : null
                  const renderedSubComponet = renderSubComponent?.({
                    row,
                    refetch: refetch,
                  })
                  return (
                    <Fragment key={`${row.id || `iDontKnowId-${i}`}`}>
                      <TrRow
                        position="relative"
                        draggable={draggable}
                        {...(draggable ?
                          {
                            onDragStart: (e) => dragStartHandler(e, row),
                            onDragOver: (e) => dragOverHandler(e),
                            onDrop: (e) => dropHandler(e, row),
                          }
                        : {})}
                      >
                        {row.getCanExpand() && (
                          <Td verticalAlign="middle" p={2}>
                            <IconButton
                              backgroundColor={'white'}
                              height={'27px'}
                              width={'27px'}
                              color={'#888'}
                              size={'xs'}
                              aria-label={'toggle show'}
                              icon={
                                row.getIsExpanded() && renderedSubComponet ?
                                  <MinusIcon />
                                : <AddIcon />
                              }
                              onClick={row.getToggleExpandedHandler()}
                            />
                          </Td>
                        )}

                        {hasMenuItems && (
                          <Td verticalAlign="middle" p={0}>
                            {menuItems && menuItems.length > 0 && (
                              <Box p={3}>
                                <MenuCell
                                  onMouseEnter={() => {
                                    if (onMouseInRowMenuButton) {
                                      onMouseInRowMenuButton(row)
                                    }
                                  }}
                                  visible={true}
                                  children={menuItems}
                                />
                              </Box>
                            )}
                          </Td>
                        )}
                        {row.getVisibleCells().map((cell, i) => {
                          return (
                            <Td
                              px="3"
                              py="2"
                              verticalAlign="middle"
                              {...td}
                              key={`${cell.id || `iDontKnowId-${i}`}`}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </Td>
                          )
                        })}
                      </TrRow>
                      {row.getIsExpanded() && renderedSubComponet && (
                        <Tr>
                          <Td colSpan={row.getVisibleCells().length + plus}>
                            {renderedSubComponet}
                          </Td>
                        </Tr>
                      )}
                    </Fragment>
                  )
                })

            : tableLoaderSkeleton(
                pageSettings?.perPage || 10,
                onRowSelectionChange ? columns.length + 1 : columns.length,
                skeletonRowHeight,
              )
            }
          </Tbody>
        </Table>
      </TableContainer>
      {pageSettings && (
        <Pagination
          {...{
            pageSettings,
            isFetching,
          }}
        />
      )}
    </Grid>
  )
}

const TrRow: FC<TableRowProps> = ({ children, ...props }) => {
  return (
    <Tr
      {...{
        ...props,
      }}
      transitionDelay={'.05s'}
      transition={'.15s ease-in'}
      _hover={{
        backgroundColor: colors.accent['100'],
      }}
    >
      {children}
    </Tr>
  )
}

export default observer(_Table)
