import {
  RouteStoreRequest,
  RouteUpdateRequest,
} from '@/api/generated/qubyApiPhp'
import { QForm } from '@/components/QForm'
import { Flex, Stack } from '@chakra-ui/react'
import { FormikErrors } from 'formik'
import React, { memo, useCallback } from 'react'
type IProps = {
  setFieldsValues: React.Dispatch<
    React.SetStateAction<Partial<RouteUpdateRequest> | undefined>
  >
  type: 'create' | 'update'
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<RouteStoreRequest>>
  setIsChange: React.Dispatch<React.SetStateAction<boolean>>
}
export const RoutesSelects = memo(
  ({ setFieldsValues, type, setFieldValue, setIsChange }: IProps) => {
    const updateState = useCallback(
      (key: string, value: string | number | null) => {
        if (type === 'update') {
          setFieldsValues((prev) => ({
            ...prev,
            [key]: value,
          }))
          setIsChange(true)
        } else {
          setFieldValue(key, value)
        }
      },
      [type, setFieldValue, setIsChange],
    )
    return (
      <Flex
        align={'flex-start'}
        justifyContent={'space-between'}
        backgroundColor={'white'}
        pl={2}
        pt={2}
        pr={2}
      >
        <Stack direction={'row'} maxW={440}>
          <QForm.Field.SelectAsync.Driver
            name={'driverId'}
            withWrapper
            onChangeValue={(value) => {
              updateState('driverId', typeof value === 'string' ? value : null)
            }}
          />
        </Stack>
        <Stack direction={'row'} maxW={230}>
          <QForm.Field.SelectAsync.Vehicle
            name={'vehicleId'}
            withWrapper
            onChangeValue={(value) => {
              updateState('vehicleId', typeof value === 'string' ? value : null)
            }}
          />
        </Stack>
        <Stack direction={'row'} maxW={200}>
          <QForm.Field.SelectAsync.DeliveryService
            name={'deliveryServiceId'}
            withWrapper
            onChangeValue={(value) => {
              updateState(
                'deliveryServiceId',
                typeof value === 'string' ? value : null,
              )
            }}
          />
        </Stack>
        <Stack direction={'row'} maxW={148}>
          <QForm.Field.Time
            name={'shiftTimeFrom'}
            withWrapper
            onChangeValue={(value) => {
              updateState('shiftTimeFrom', value)
            }}
          />
        </Stack>
        <Stack direction={'row'} maxW={140}>
          <QForm.Field.Time
            name={'shiftTimeTo'}
            withWrapper
            onChangeValue={(value) => {
              updateState('shiftTimeTo', value)
            }}
          />
        </Stack>
        <Stack direction={'row'} maxW={81}>
          <QForm.Field.Money
            name={'cost'}
            withWrapper
            onChangeValue={(value) => {
              updateState('cost', value)
            }}
          />
        </Stack>
      </Flex>
    )
  },
)
