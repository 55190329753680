import React from 'react'
import { Grid, Text } from '@chakra-ui/react'
import { SelectProps } from '@/types/global'
import Select from '@/components/atoms/Select'
import { SelectFieldOptionType } from '@/components/atoms/Select/Select'

interface Props<T extends SelectFieldOptionType> {
  title?: string
  subtitle?: string
  selectProps: SelectProps<T>
  caption?: string
  error?: string | string[]
}

const SelectField = <T extends SelectFieldOptionType = SelectFieldOptionType>({
  title,
  subtitle,
  selectProps,
  caption,
}: Props<T>) => {
  return (
    <Grid gridGap="10px" position="relative">
      {title && (
        <Text lineHeight="1" textStyle="h5">
          {title}
        </Text>
      )}
      {subtitle && <Text textStyle="field-caption">{subtitle}</Text>}

      <Select<T> {...selectProps} />

      {caption && <Text textStyle="caption">{caption}</Text>}
    </Grid>
  )
}

export default SelectField
