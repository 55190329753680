import React from 'react'
import { QSelectPropsType } from '@/components/QForm/QField/QSelect/Qselect.types'
import { useTranslation } from 'react-i18next'
import { deliveryDlvReturnFiltersOptions } from '@/constants/options'
import { IOption } from '@/types/global'
import { QForm } from '@/components/QForm/QForm'
import { DeliveryDlvReturnFiltersEnum } from '@/api/qubyApiEgoDlv'

type QSelectReturnsDlvFiltersPropsType = Omit<
  QSelectPropsType<
    IOption<DeliveryDlvReturnFiltersEnum>,
    DeliveryDlvReturnFiltersEnum
  >,
  'options'
>
export const QSelectReturnsDlvFilters = (
  props: QSelectReturnsDlvFiltersPropsType,
) => {
  const [t] = useTranslation()
  const options = deliveryDlvReturnFiltersOptions(t)

  return (
    <QForm.Field.Select
      {...{
        ...props,
        options,
      }}
    />
  )
}
