import { Box, Button, Flex, Text } from '@chakra-ui/react'
import React, { memo } from 'react'
import PlanningTable from './PlanningTable'
import { FormikErrors } from 'formik'
import {
  RouteStoreRequest,
  RunRequestResource,
  UpdateInternalRouteByIdApiArg,
} from '@/api/generated/qubyApiPhp'
import { RouteInitialState } from '../PlanningRoutesPage'
import { ModalController } from '@/hooks/useModalState'
type IRoutesType = {
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>
  selectedRunRequestIds: string[]
  showItems: boolean
  setShowCreateRoute: React.Dispatch<React.SetStateAction<boolean>>
  setCreateInitialState: React.Dispatch<React.SetStateAction<RouteInitialState>>
  runRequest: RunRequestResource[]
  setRunRequest: React.Dispatch<React.SetStateAction<RunRequestResource[]>>
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<RouteStoreRequest>>
  disableNewItem: boolean
  showCreateRoute: boolean
  isRoute?: boolean
  addRunRequestRoute: ModalController<UpdateInternalRouteByIdApiArg>
}
export const RunRequestWraper = memo(
  ({
    setSelectedIds,
    selectedRunRequestIds,
    showItems,
    setShowCreateRoute,
    setCreateInitialState,
    runRequest,
    setRunRequest,
    setFieldValue,
    disableNewItem,
    showCreateRoute,
    isRoute = false,
    addRunRequestRoute,
  }: IRoutesType) => {
    return (
      <Flex flexDir={'column'} gap={3}>
        <Flex align={'center'} mb={4}>
          <Text textStyle="h4">Рейсы без маршрута</Text>
        </Flex>
        <Box maxH={400}>
          <PlanningTable
            {...{
              setSelectedIds,
              selectedRunRequestIds,
              runRequest,
              setFieldValue,
              showCreateRoute,
            }}
          />
        </Box>
        {showItems && (
          <Box alignSelf={'flex-end'}>
            {isRoute ?
              <Button
                alignSelf={'flex-end'}
                size="sm"
                mr={3}
                colorScheme="blue"
                variant={disableNewItem ? 'disabled' : 'accent'}
                onClick={() => {
                  if (!disableNewItem) {
                    addRunRequestRoute.openModal({
                      routeUpdateRequest: {
                        runRequestIds: selectedRunRequestIds,
                      },
                    })
                  }
                }}
              >
                Добавить в существующий
              </Button>
            : null}

            <Button
              alignSelf={'flex-end'}
              size="sm"
              colorScheme="blue"
              variant={disableNewItem ? 'disabled' : 'accent'}
              onClick={() => {
                if (!disableNewItem) {
                  setShowCreateRoute(true)
                  const filteredRunRequest = runRequest.filter((obj) =>
                    selectedRunRequestIds.includes(obj.id),
                  )
                  const newList = runRequest.filter(
                    (obj) => !selectedRunRequestIds.includes(obj.id),
                  )

                  setCreateInitialState((prev) => ({
                    ...prev,
                    runRequests: filteredRunRequest || [],
                  }))
                  setRunRequest(newList)
                }
              }}
            >
              Создать маршрут
            </Button>
          </Box>
        )}
      </Flex>
    )
  },
)
