import {
  qubyApiPhp,
  RouteResource,
  UpdateInternalRouteByIdApiArg,
} from '@/api/generated/qubyApiPhp'
import { ModalController } from '@/hooks/useModalState'
import React, { useState } from 'react'
import { notificationsStore } from '@/stores/global.store'
import { qFormCollectErrors } from '@/components/QForm/qFormCollectErrors'
import { Button, Flex, Text } from '@chakra-ui/react'
import Modal from '@/components/Modal'
import SelectField from '@/components/moleculs/fields/SelectField'
type Props = {
  addRunRequestRoute: ModalController<UpdateInternalRouteByIdApiArg>
  refetchRunrequest?: () => void
  refetchRoutes?: () => void
  date: string
}

export const RunRequestRouteModal = ({
  addRunRequestRoute,
  refetchRunrequest = () => {},
  refetchRoutes = () => {},
  date,
}: Props) => {
  const [idRoute, setIdRoute] = useState('')
  const [updateInternalRoute] =
    qubyApiPhp.endpoints.updateInternalRouteById.useMutation()
  const modalData = addRunRequestRoute.getData()

  const {
    data,
    isLoading: isLoadingDriver,
    refetch,
  } = qubyApiPhp.endpoints.getInternalRoutesList.useQuery(
    {
      routeDate: date,
    },
    { skip: !date },
  )
  const onCloseModal = () => {
    addRunRequestRoute.closeModal()
  }
  const routesOptions = data?.data || []
  return (
    <Modal
      {...addRunRequestRoute}
      onCloseModal={onCloseModal}
      header={'Добавить рейс в существующий маршрут'}
      buttons={
        <Flex gap={4}>
          <Button variant="outline" onClick={onCloseModal}>
            <Text textStyle="h5">Отменить</Text>
          </Button>
          <Button
            variant={!idRoute ? 'disabled' : 'accent'}
            onClick={async () => {
              if (!idRoute) return
              const route = data?.data?.find((el) => el?.id === idRoute)
              const response = await updateInternalRoute({
                id: idRoute,
                routeUpdateRequest: {
                  driverId: route?.driverId || undefined,
                  vehicleId: route?.vehicleId || undefined,
                  deliveryServiceId: route?.deliveryServiceId || undefined,
                  shiftTimeFrom: route?.shiftTimeFrom,
                  shiftTimeTo: route?.shiftTimeTo,
                  cost: route?.cost,
                  runRequestIds: [
                    ...(modalData?.routeUpdateRequest?.runRequestIds || []),
                    ...(route?.runRequests?.map((el) => el.id) || []),
                  ],
                },
              })
              const errors = await qFormCollectErrors(response)
              if (!errors) {
                notificationsStore.pushSuccess({
                  message: 'Успешно сохранено',
                })
                addRunRequestRoute.closeModal()
                refetchRunrequest()
                refetchRoutes()
              }
              return errors
            }}
          >
            <Text textStyle="h5">Сохранить</Text>
          </Button>
        </Flex>
      }
    >
      <SelectField<RouteResource>
        selectProps={{
          disabled: routesOptions.length === 0,
          placeholder: 'Выберите маршрут',
          options: routesOptions,
          value: idRoute,
          getSelectedValueInputString: ({ routeName }) => routeName || '',
          renderOption: ({ routeName }) => (
            <Text textStyle="text4">{routeName}</Text>
          ),
          handleChange: ({ id }) => {
            setIdRoute(id)
          },
        }}
      />
    </Modal>
  )
}
