import { QForm } from '@/components/QForm'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import React, { memo, useCallback, useEffect, useState } from 'react'
import {
  DeleteInternalRouteByIdApiArg,
  qubyApiPhp,
  RouteResource,
  RouteStoreRequest,
  RouteUpdateRequest,
  RunRequestResource,
} from '@/api/generated/qubyApiPhp'
import {
  RouteInitialState,
  routeStoreRequestSchema,
  routeUpdateRequestSchema,
} from '../PlanningRoutesPage'
import RouteTable from './RouteTable'
import { notificationsStore } from '@/stores/global.store'
import { qFormCollectErrors } from '@/components/QForm/qFormCollectErrors'
import api from '@/services/api'
import { FormikErrors, FormikState } from 'formik'
import { RoutesSelects } from './RoutesSelects'
import { usePlanningRoutesTableQueryContext } from '../usePlanningRoutesTableQueryContext'
import useModalState from '@/hooks/useModalState'
import { ConfirmRouteModal } from '../modals/ConfirmRouteModal'

type IRoutesType = {
  route?: RouteResource
  queryRoutesIsLoading: boolean
  queryRoutesIsFetching: boolean
  runRequestRefetch?: () => void
  routesRefetch?: () => void
  type: 'create' | 'update'
  routeInitialState?: RouteInitialState
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<RouteStoreRequest>>
  handleSubmit?: (e?: React.FormEvent<HTMLFormElement>) => void
  setShowCreateRoute?: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedIds?: React.Dispatch<React.SetStateAction<string[]>>
  setRunRequest?: React.Dispatch<React.SetStateAction<RunRequestResource[]>>
  resetForm?: (
    nextState?: Partial<FormikState<RouteStoreRequest>> | undefined,
  ) => void
  disableButton: boolean
  setDisableButton: React.Dispatch<React.SetStateAction<boolean>>
  draggable?: boolean
  withGetMenu?: boolean
}
export type IRoutesWrapperTable = Partial<RouteUpdateRequest>

export const RoutesWrapper = memo(
  ({
    route,
    queryRoutesIsLoading,
    queryRoutesIsFetching,
    disableButton,
    runRequestRefetch = () => {},
    routesRefetch = () => {},
    type,
    routeInitialState,
    setFieldValue,
    handleSubmit = () => {},
    setShowCreateRoute,
    setSelectedIds,
    setRunRequest,
    resetForm,
    setDisableButton,
    draggable = false,
    withGetMenu = false,
  }: IRoutesType) => {
    const [filedsValues, setFieldsValues] = useState<IRoutesWrapperTable>()
    const [runRequestList, setRunRequestList] = useState<RunRequestResource[]>(
      [],
    )
    const [isChange, setIsChange] = useState(false)

    const [isDataLoaded, setIsDataLoaded] = useState(false)
    const [deleteRoute] =
      qubyApiPhp.endpoints.deleteInternalRouteById.useMutation()
    const [updateInternalRoute] =
      qubyApiPhp.endpoints.updateInternalRouteById.useMutation()
    const updateStateRoute = () => {
      setFieldsValues({
        driverId: route?.driverId || '',
        vehicleId: route?.vehicleId || '',
        deliveryServiceId: route?.deliveryServiceId || '',
        shiftTimeFrom: route?.shiftTimeFrom || '',
        shiftTimeTo: route?.shiftTimeTo || '',
        cost: route?.cost,
        runRequestIds: route?.runRequests?.map((el) => el?.id) || [],
      })
      setRunRequestList(route?.runRequests || [])
    }
    const {
      query: { data },
    } = usePlanningRoutesTableQueryContext()
    const title =
      route ? `Маршрут ${route?.routeName}` : 'Создание нового маршрута'
    useEffect(() => {
      if (route && type === 'update') {
        updateStateRoute()
        setIsDataLoaded(true)
      }
      if (routeInitialState && type === 'create') {
        setFieldsValues({
          ...routeInitialState,
          runRequestIds: routeInitialState?.runRequests?.map((el) => el?.id),
        })
        setRunRequestList(routeInitialState?.runRequests || [])
        setIsDataLoaded(true)
        setDisableButton(false)
      }
    }, [route])

    const handleDeleteRoute = async () => {
      const result = await deleteRoute({ id: route?.id })
      const errors = await qFormCollectErrors(result)
      if (!errors) {
        notificationsStore.pushSuccess({
          message: 'Маршрут удален',
        })
        runRequestRefetch()
        routesRefetch()
      }
      if (errors) {
        notificationsStore.pushError({
          message: errors?.message,
        })
      }
      setDisableButton(false)
      return errors
    }
    const updateRoute = async () => {
      if (!runRequestList.length) {
        confirmModalProps.openModal({
          id: route?.id,
        })
        setDisableButton(false)
      }
      if (runRequestList.length) {
        const result = await updateInternalRoute({
          id: route?.id,
          routeUpdateRequest: {
            ...(filedsValues?.driverId ?
              { driverId: filedsValues?.driverId }
            : {}),
            ...(filedsValues?.vehicleId ?
              { vehicleId: filedsValues?.vehicleId }
            : {}),
            deliveryServiceId: filedsValues?.deliveryServiceId,
            shiftTimeFrom: filedsValues?.shiftTimeFrom,
            shiftTimeTo: filedsValues?.shiftTimeTo,
            cost: filedsValues?.cost,
            runRequestIds: runRequestList?.map((el) => el?.id) || [],
          },
        })
        const errors = await qFormCollectErrors(result)
        if (!errors) {
          notificationsStore.pushSuccess({
            message: 'Маршрут сохранен',
          })
          runRequestRefetch()
          routesRefetch()
          setIsChange(false)
        }
        if (errors) {
          notificationsStore.pushError({
            message: errors?.message,
          })
        }
        setDisableButton(false)
        return errors
      }
    }
    const cancelRoute = async () => {
      setIsDataLoaded(false)
      await updateStateRoute()
      setIsDataLoaded(true)
      setDisableButton(false)
      setIsChange(false)
    }
    const downloadRoute = async () => {
      await api.download.getInternalRouteListExport({ id: route?.id })
      setDisableButton(false)
    }
    const memoizedSetFieldValue = useCallback(setFieldValue, [setFieldValue])
    const memoizedSetFieldsValues = useCallback(setFieldsValues, [
      setFieldsValues,
    ])
    const confirmModalProps = useModalState<DeleteInternalRouteByIdApiArg>()
    return (
      <>
        {confirmModalProps.isOpen && (
          <ConfirmRouteModal
            {...{ confirmModalProps, runRequestRefetch, routesRefetch }}
          />
        )}
        <Flex
          flexDir={'column'}
          w={'100%'}
          gap={3}
          border={'1px solid red'}
          p={5}
          borderColor={'#8080804d'}
          borderRadius={'10px'}
          backgroundColor={'#f0f3f781'}
        >
          {isDataLoaded && (
            <QForm
              validationSchema={
                type === 'update' ?
                  routeUpdateRequestSchema
                : routeStoreRequestSchema
              }
              initialValues={filedsValues}
            >
              <Flex align={'center'} mb={4}>
                <Text textStyle="h5">{title}</Text>
              </Flex>

              <RoutesSelects
                {...{
                  setFieldsValues: memoizedSetFieldsValues,
                  type,
                  setFieldValue: memoizedSetFieldValue,
                  setIsChange,
                }}
              />

              <Box maxH={400} backgroundColor={'white'}>
                <RouteTable
                  {...{
                    runRequest: runRequestList || [],
                    isLoading: queryRoutesIsLoading,
                    isFetching: queryRoutesIsFetching,
                    setRunRequestList,
                    runRequestRefetch,
                    routesRefetch,
                    draggable,
                    withGetMenu,
                    setIsChange,
                  }}
                />
              </Box>
              {type === 'update' ?
                <Box alignSelf={'flex-end'}>
                  <Flex flexDir={'row'} gap={3}>
                    <Button
                      alignSelf={'flex-end'}
                      size="sm"
                      colorScheme="green"
                      variant={disableButton ? 'disabled' : undefined}
                      onClick={() => {
                        if (disableButton) return
                        setDisableButton(true)
                        downloadRoute()
                      }}
                    >
                      Печать маршрутного листа
                    </Button>
                    <Button
                      alignSelf={'flex-end'}
                      size="sm"
                      colorScheme="blue"
                      variant={
                        disableButton || !isChange ? 'disabled' : undefined
                      }
                      onClick={() => {
                        if (disableButton || !isChange) return
                        setDisableButton(true)
                        updateRoute()
                      }}
                    >
                      Сохранить
                    </Button>
                    <Button
                      alignSelf={'flex-end'}
                      size="sm"
                      colorScheme="teal"
                      variant={
                        disableButton || !isChange ? 'disabled' : undefined
                      }
                      onClick={() => {
                        if (disableButton || !isChange) return
                        cancelRoute()
                      }}
                    >
                      Отменить
                    </Button>
                    <Button
                      alignSelf={'flex-end'}
                      size="sm"
                      colorScheme="red"
                      variant={disableButton ? 'disabled' : undefined}
                      onClick={() => {
                        if (disableButton) return
                        setDisableButton(true)
                        handleDeleteRoute()
                      }}
                    >
                      Удалить
                    </Button>
                  </Flex>
                </Box>
              : <Box alignSelf={'flex-end'}>
                  <Flex flexDir={'row'} gap={3}>
                    <Button
                      alignSelf={'flex-end'}
                      size="sm"
                      colorScheme="gray"
                      onClick={() => {
                        setShowCreateRoute && setShowCreateRoute(false)
                        setSelectedIds && setSelectedIds([])
                        resetForm && resetForm()
                        setRunRequest && setRunRequest(data?.data || [])
                      }}
                    >
                      Отменить
                    </Button>
                    <Button
                      alignSelf={'flex-end'}
                      size="sm"
                      colorScheme="blue"
                      onClick={() => handleSubmit()}
                    >
                      Сохранить
                    </Button>
                  </Flex>
                </Box>
              }
            </QForm>
          )}
        </Flex>
      </>
    )
  },
)
