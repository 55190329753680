import React, { memo } from 'react'
import Table from '@/components/moleculs/Table'
import { useRunRequestTableColumns } from '../../runRequest/useRunRequestTableColumns'
import {
  RunRequestResource,
  TransitionTiIdleRunInternalRunRequestApiArg,
} from '@/api/generated/qubyApiPhp'
import { useRoutesTableMenuItems } from './useRoutesTableMenuItems'
import useModalState from '@/hooks/useModalState'
import { ConfirmIdleRunModal } from '@/_app/pages/planningRoutes/modals/ConfirmIdleRunModal'
type IProps = {
  runRequest: RunRequestResource[]
  isLoading: boolean
  isFetching: boolean
  setRunRequestList: React.Dispatch<React.SetStateAction<RunRequestResource[]>>
  runRequestRefetch?: () => void
  routesRefetch?: () => void
  draggable?: boolean
  withGetMenu?: boolean
  setIsChange: React.Dispatch<React.SetStateAction<boolean>>
}
const RouteTable = memo(
  ({
    runRequest,
    isLoading,
    isFetching,
    setRunRequestList,
    runRequestRefetch = () => {},
    routesRefetch = () => {},
    draggable = false,
    withGetMenu = false,
    setIsChange,
  }: IProps) => {
    const columns = useRunRequestTableColumns({ hideAttachments: false })
    const confirmIdleRunModalProps =
      useModalState<TransitionTiIdleRunInternalRunRequestApiArg>()
    const getMenuItems = useRoutesTableMenuItems({
      runRequest,
      setRunRequestList,
      confirmIdleRunModalProps,
      setIsChange,
    })

    return (
      <>
        {confirmIdleRunModalProps.isOpen && (
          <ConfirmIdleRunModal
            {...{ confirmIdleRunModalProps, runRequestRefetch, routesRefetch }}
          />
        )}
        <Table
          draggableChangeStateList={setIsChange}
          setData={setRunRequestList}
          skeletonRowHeight={'32px'}
          draggable={draggable}
          {...(withGetMenu ? { getMenuItems } : {})}
          {...{
            columns,
            isLoading,
            isFetching,
            data:
              !runRequest ?
                []
              : runRequest.map((el) => ({
                  ...el,
                  id: String(el.id),
                })) || [],
          }}
        />
      </>
    )
  },
)

export default RouteTable
